import { CabButton } from "@CabComponents/CabButton";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { Alert, Box, FormControl, FormLabel, Link, Typography } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import colors from "../../colors";
import { emailRegex, PAGE_URL } from "../../constants";
import StepHeader from "../../components/Onboarding/StepHeader";
import VerificationCodeInput from "../../components/Onboarding/VerificationCodeInput";
import PasswordInput from "../../components/Onboarding/PasswordInput";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";


export interface ResetPasswordProps {
  email?: string;
  loading?: boolean;
  resetStep: number;
  verifiedEmail: string;
  offerNewCode?: boolean;
  submissionErrors: string[];
  onResendCode: () => void;
  onSubmitEmail: (values: FormInput) => void;
  onResetPassword: (password: string, code: string) => void;
  toastMessage: string;
  onClearToastMessage: () => void;
}

interface FormInput {
  email: string;
  password: string;
  confirmPassword: string;
}

const ResetPassword = ({ 
  onSubmitEmail, onResetPassword, email, submissionErrors, resetStep, verifiedEmail, loading, 
  onResendCode, toastMessage, onClearToastMessage, offerNewCode
}: ResetPasswordProps): ReactElement => {
  const { control, formState: {errors, isSubmitting }, watch, handleSubmit: handleFormSubmit } = useForm<FormInput> ({
    criteriaMode: 'all',
    defaultValues: { email: email ?? '', password: '', confirmPassword: '' },
  });

  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (code && password) {
      onResetPassword(password, code);
    }
  }, [code, password, onResetPassword]);

  const watchPassword = watch('password');

  const passwordsMatch = (confirmPassword: string): boolean => confirmPassword === watchPassword;

  const setNewPasswordClick = (value: string) => {
    setCode(value);
    handleFormSubmit(onSubmitReset)();
  };

  const onSubmitReset = (values: FormInput) => {
    if (values.password) {
      setPassword(values.password);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2} width="100%">
      {resetStep === 0 && (
        <>
          <StepHeader
            title="Reset password"
            text="We'll send you an email with a code to update your password"
          />

          <form onSubmit={handleFormSubmit(onSubmitEmail)} style={{ width: 'inherit' }}>
            <Box display="flex" flexDirection="column" width="100%" flex={1} alignItems="center">
              <Box display="flex" flexDirection="column" gap={3} marginTop={4} width="100%"
                maxWidth="350px" flex={1} alignItems="center"
              >
                <FormControl sx={{ flex: 1, width: '100%' }}>
                  <FormLabel>Work email</FormLabel>
                  <Controller name="email" control={control} rules={{ required: true, pattern: emailRegex }} 
                    render={({ field: { ref, ...field } }) => (
                      <CabTextInput {...field} />
                    )}
                  />
                  {errors.email && (
                    <Alert severity="error" sx={{ marginTop: 1 }}>
                      {errors?.email?.type === 'required' ? 'Email is required'
                        : errors.email?.type === 'pattern' ? 'Please enter a valid email address'
                          : null}
                    </Alert>
                  )}
                </FormControl>

                <CabButton
                  size="large"
                  type="submit"
                  sx={{ width: '100%' }}
                  disabled={isSubmitting}
                >
                  Submit
                </CabButton>

                <Box display="flex" gap={1}>
                  <Link component={RouterLink} to={PAGE_URL.LOGIN} variant="body2" color={colors.black600}>
                    Back to Login
                  </Link>
                </Box>
              </Box>
            </Box>
          </form>
        </>
      )}
      {resetStep === 1 && (
        <>
          <StepHeader
            title="Reset password"
            text={<span>
              If an account exists for <Box component="span" sx={{fontWeight: "bold"}}>{verifiedEmail}</Box>, 
              a verification code will be sent to that email address. 
              Please create a new password and enter that code below.
            </span>
            }
          />
          <form onSubmit={handleFormSubmit(onSubmitReset)} style={{ width: 'inherit' }}>
            <Box display="flex" flexDirection="column" width="100%" flex={1} alignItems="center">
              <Box display="flex" flexDirection="column" gap={3} marginTop={4} width="100%"
                maxWidth="350px" flex={1} alignItems="center"
              >
                <FormControl sx={{ flex: 1, width: '100%' }}>
                  <FormLabel>Password</FormLabel>
                  <PasswordInput name="password" control={control} error={errors.password} isNew/>
                </FormControl>

                <FormControl sx={{ flex: 1, width: '100%' }}>
                  <FormLabel>Confirm Password</FormLabel>
                  <Controller name="confirmPassword" control={control} 
                    rules={{ required: true, validate: passwordsMatch }} 
                    render={({ field: { ref, ...field } }) => (
                      <CabTextInput {...field} type={showPassword ? 'text' : 'password'} endIcon={
                        <CabIcon
                          color="black"
                          onClick={handleClickShowPassword}
                          alt={showPassword ? 'hide password' : 'show password'}
                          Icon={showPassword ? IoEyeOffOutline : IoEyeOutline}
                        />
                      }/>
                    )}
                  />
                  {errors.confirmPassword && (
                    <Alert severity="error" sx={{ marginTop: 1 }}>
                      {errors?.confirmPassword?.type === 'required' ? 'Confirm password is required' :
                        errors?.confirmPassword.type === 'validate' ? 'Passwords do not match' : null}
                    </Alert>
                  )}
                </FormControl>
            
              </Box>
            </Box>
          </form>
          <VerificationCodeInput
            length={6}
            label='Enter your code:'
            placeholder='-'
            errorMessage={submissionErrors}
            buttonText='Set New Password'
            offerNewCode={offerNewCode}
            loading={loading}
            onResendCode={onResendCode}
            onComplete={setNewPasswordClick}
            toastMessage={toastMessage}
            onClearToastMessage={onClearToastMessage}
          />
          <Box display="flex" gap={1}>
            <Typography variant="body2" color={colors.black600}>Remembered your password?</Typography>
            <Link component={RouterLink} to={PAGE_URL.LOGIN} variant="body2" color={colors.black600}>
              Sign in
            </Link>
          </Box>
        </>
      )}
      {resetStep === 2 && (
        <>
          <StepHeader
            title="Reset password"
            text="Your new password has been confirmed, logging in . . ."
          />
          {submissionErrors && (
            <>
              {submissionErrors.map((err, idx) => (
                <Alert key={idx} severity="error" sx={{ marginTop: 1, width: '100%' }}>
                  {err}
                </Alert>
              ))}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default ResetPassword;